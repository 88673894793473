@font-face {
  font-family: "SAP-icons";
  src: url("fonts/SAP-icons.woff2") format("woff2"),
    url("fonts/SAP-icons.woff") format("woff"),
    url("fonts/SAP-icons.ttf") format("truetype"), local("SAP-icons");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 400;
  src: local("72"), url("fonts/72-Regular.woff2") format("woff2"),
    url("fonts/72-Regular.woff") format("woff");
}

@font-face {
  font-family: "72";
  font-style: normal;
  font-weight: 700;
  src: local("72-Bold"), url("fonts/72-Bold.woff2") format("woff2"),
    url("fonts/72-Bold.woff") format("woff");
}

.asyncapi__code-pre {
    overflow-x: auto;
    margin: 0;
    font-size: 13px;
    padding: 12px;
    background: #aabacb;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
